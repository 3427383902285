const $DEFAULT_REQUEST_HEADERS = {
	"Accept":       "application/json",
	"Content-Type": "application/json"
};

const parseExpression = (expression, element) => {

	// Number or Boolean expression:
	if(/^(\d+(\.\d*)?|\.\d+)$/.test(expression)) { return Number(expression); }
	if(["true", "false"].includes(expression))   { return expression == "true"; }

	// Tagged expression:
	if(/^(~|<|#|\.?\${1,2}|\.\$\^):.+/.test(expression)) {
		const [tag, value] = expression.split(/:(.*)/);
		switch(tag) {
			case "~":   return value;
			case "<":   return JSON.parse(value);
			case "#":   return document.getElementById(value);
			case "$":   return document.querySelector(value);
			case "$$":  return document.querySelectorAll(value);
			case ".$":  return element.querySelector(value);
			case ".$$": return element.querySelectorAll(value);
			case ".$^": return element.closest(value);
		}
	}

	// Context path expression:
	return expression.replace(/\[(\d+)\]/, ".$1").split(".").reduce((obj, key) => obj && obj[key], window);

};

export function getElements($, name) {
	// @todo: Allow elements names to be a dot-delimited path, then deep merge all elements.
	const query = `.//*[@*[starts-with(name(), "@${name}:")]]`;
	const result = new XPathEvaluator().evaluate(query, $, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE);
	return Object.fromEntries(
		Array.from(result).map(element => {
			const {name: name_} = Array.from(element.attributes).find(({name: name_}) => name_.startsWith(`@${name}:`));
			return [name_.split(":")[1].toCamelCase(), element];
		})
	);
};

export function getProps($, name) {
	// @todo: Allow prop names to be a dot-delimited path, then deep merge all props.
	return Object.fromEntries(
		Array.from($.attributes)
		.filter(({name: name_}) => name_.startsWith(`@${name}:`))
		.map(({name: name_, value}) => [name_.split(":")[1].toCamelCase(), parseExpression(value, $)])
	);
};

export function listen(targets, types, handler, options={}) {
	const options_ = {preventDefault: false, passive: !options.preventDefault, ...options};
	const handler_ = (event, ...args) => {
		options_.preventDefault && event.preventDefault();
		handler != null && handler(event, ...args);
	};
	for(const target of [targets].flat()) {
		types.split(" ").forEach(type => {
			if(/^key(down|up):.+/.test(type)) {
				const [type_, key] = type.split(":");
				const handler__ = (event, ...args) => event.key == key.toPascalCase() && handler_(event, ...args);
				target.addEventListener(type_, handler__, options_);
				return handler__;
			}
			target.addEventListener(type, handler_, options_);
		});
	}
	return handler_;
};

export function unlisten(targets, types, handler) {
	for(const target of [targets].flat()) {
		types.split(" ").forEach(type => target.removeEventListener(type, handler));
	}
};

export class FetchJSONError extends Error {}
export async function fetchJSON(method, url, data, throwOn404=false) {
	const url_ = new URL(url, location.origin);
	const response = await fetch(url_, {method, headers: $DEFAULT_REQUEST_HEADERS, body: JSON.stringify(data)});
	if(response.status == 404 && !throwOn404) {
		return null;
	}
	if(!response.ok) {
		throw new FetchJSONError(`${method.toUpperCase()} [${url_}]: ${response.status} ${response.statusText}`.trim());
	}
	if(/^application\/json(;|$)/.test(response.headers.get("Content-Type"))) {
		return await response.json();
	}
};
