import {listen} from "../lib/component.js";
import Template from "../lib/template.js";
import Component from "./_component.js";

export default class AttendeeSocialStack extends Component {

	constructor($, $$, props) {

		Object.assign(props, {
			itemCountMin: 1,
			itemCountMax: 5
		});

		super(...arguments, {template: new Template($$.template)});

		this.itemCount = $.querySelectorAll("li").length;
		this.itemCount == 0 && this.addItem();

		listen($, "click", event => {
			switch(event.target.dataset.action) {
				case "addItem":		 this.addItem();                              break;
				case "removeItem": this.removeItem(event.target.closest("li")); break;
			}

		});

	}

	addItem() {
		if(this.itemCount >= this.props.itemCountMax) {
			return;
		}
		this.itemCount++;
		this.template(this.$, {}, "append");
	}

	removeItem(item) {
		if(this.itemCount <= this.props.itemCountMin) {
			item.querySelector("select").value = "";
			item.querySelector("input").value = "";
			return;
		}
		this.itemCount--;
		item.remove();
	}

}
