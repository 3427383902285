import {listen} from "../lib/component.js";
import Component from "./_component.js";

export default class Details extends Component {

	constructor($, $$, props) {
		super(...arguments);
		listen($, "toggle", () => this.toggle());
	}

	toggle() {
		this.$.dataset.state != "opening" && this.$.open && this.$.querySelector("[autofocus]")?.focus()
		sleep(100, () => delete this.$.dataset.state);
	}

}
