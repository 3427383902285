import {listen} from "../lib/component.js";
import Controller from "./_controller.js";

export default class IndexController extends Controller {

	initialize() {

		const ticketCategoryForm = this.$$.ticketCategoryForm.component;
		const indexForm = this.$$.indexForm.component;

		const params = new URLSearchParams(location.search);
		params.has("token") && sleep(500, () => {
				ticketCategoryForm.ƒ.token.value = params.get("token");
				ticketCategoryForm.props.details.setState("opening");
				ticketCategoryForm.props.details.open = true;
				ticketCategoryForm.$.requestSubmit();
		});

		listen(ticketCategoryForm.$, "@ticket-category-form:load", event => {
			indexForm.addTicketCategory(event.detail.ticketCategory);
		});

		listen(
			indexForm.$$.invoiceDetails,
			"toggle",
			() => indexForm.$$.invoiceCountrySelect.component.render(),
			{once: true}
		);

	}

}
