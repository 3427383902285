import {listen, fetchJSON} from "../lib/component.js";
import Template from "../lib/template.js";
import Controller from "./_controller.js";

export default class TicketOrderController extends Controller {

	constructor($, $$, props) {

		super(...arguments, {
			cardTemplate: new Template($$.cardTemplate),
			attendeeListTemplate: new Template($$.attendeeListTemplate)
		});

		listen($$.button, "click", async () => this.submit());

		// @feature:
		listen($, "change", () => {
			const progress = Array
			.from($.querySelectorAll("[required]"))
			.reduce((progress_, input, i, inputs) => ((input.validity.valid ? 1 : 0) / inputs.length) + progress_, 0);
			document.body.style.setProperty("--progress-value", `${(progress * 100).toFixed(2)}%`);
			document.body.style.setProperty("--progress-label", `"${(progress * 100).toFixed()}%"`);
		});

	}

	async initialize() {

		const time = Date.now();
		const id = location.pathname.split("/").pop();
		const url = new URL(`/api/ticket-orders/${id}`, location.origin);
		try {
			this.ticketOrder = await fetchJSON("GET", url, undefined, true);
		}
		catch(error) {
			this.dispatch("error", {error}, {global: true});
			throw(error);
		}

		let attendeeCount = 0;
		let attendeeIndex = 0;
		const attendees = this.ticketOrder.ticketCategories.map(ticketCategory => (
			ticketCategory.attendees.map(attendee => {
				attendee.id != null && attendeeCount++;
				return {...attendee, index: attendeeIndex++, ticketCategoryName: ticketCategory.name};
			})
		))
		.flat();

		// @feature:
		const progress = attendeeCount / attendees.length;
		sleep(500, () => {
				document.body.style.setProperty("--progress-value", `${(progress * 100).toFixed(2)}%`);
				document.body.style.setProperty("--progress-label", `"${(progress * 100).toFixed()}%"`);
			}
		);

		const delay = Date.now() - time;
		await sleep(delay < 100 ? 0 : 500 - delay, () => this.cardTemplate(this.$$.cardCanvas, this.ticketOrder));
		this.attendeeListTemplate(this.$$.attendeeListCanvas, attendees);

	}

	async submit() {
		this.$$.button.setState("pending");
		try {
			const forms = Array.from(this.$$.attendeeListCanvas.querySelectorAll("form"));
			await Promise.all(forms.map(form => form.checkValidity() && form.component.submit()));
		}
		finally {
			this.$$.button.removeState();
		}
	}

}
