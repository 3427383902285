import {fetchJSON} from "../lib/component.js";
import Component from "./_component.js";

const url = new URL("/assets/data/iso-3166-alpha-2.json", location.origin);

export default class CountrySelect extends Component {

	constructor($, $$, props) {
		Object.assign(props, {render: false});
		super(...arguments);
		props.render && this.render();
	}

	async render(force=false) {
		if(!!this.rendered && !force) {
			return;
		}
		this.$.append(document.createElement("hr"));
		Object.entries(await this.getCountries()).forEach(([code, name]) => this.$.options.add(new Option(name, code)));
		this.props.value != null && (this.$.value = this.props.value);
		this.rendered = true;
	}

	async getCountries() {
		try {
			return await fetchJSON("GET", url, undefined, true);
		}
		catch {} // Fail silently.
	}

}
