import Form from "./_form.js";

export default class AttendeeForm extends Form {

	serialize() {
		return {
			name:        String.fromInput(this.ƒ.name),
			email:       String.fromInput(this.ƒ.email),
			affiliation: String.fromInput(this.ƒ.affiliation),
			pronouns:    String.fromInput(this.ƒ.pronouns),
			diet:        String.fromInput(this.ƒ.diet),
			// @todo: Add socials.
			hidden:			 !this.ƒ.hidden.checked,
			ticketId:    String.fromInput(this.ƒ.ticketId)
		}
	}

	async submit() {
		this.$$.button?.setState("pending");
		try {
			await Promise.all([super.submit(), sleep(500)]);
			if(this.data?.id != null) {
				this.$.method = "PATCH";
				this.$.action = `/api/attendees/${this.data.id}`;
			}
		}
		finally {
			this.$$.button?.removeState();
		}
	}

}
