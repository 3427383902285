import {fetchJSON} from "../lib/component.js";
import Template from "../lib/template.js";
import Controller from "./_controller.js";

export default class AttendeeController extends Controller {

	constructor($, $$, props) {
		super(...arguments, {template: new Template($$.template)});
	}

	async initialize() {
		const id = location.pathname.split("/").pop();
		try {
			this.attendee = await fetchJSON("GET", new URL(`/api/attendees/${id}`, location.origin), undefined, true);
		}
		catch(error) {
			this.dispatch("error", {error}, {global: true});
			throw(error);
		}
		this.template(this.$, {attendee: this.attendee});
	}

}
